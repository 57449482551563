<template>
	<div class="w-full">
		<div class="flex justify-between items-center">
			<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Медицинские организации</h1>
			<div class="flex">
				<Button v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="mr-2" text="Добавить МО" icon="add" @click.native="openPopUp()" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>
		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<!-- <div class="flex w-full h-auto mt-4 px-7 py-5 rounded-lg bg-white filter shadow-default"> -->
			<div class="relative w-full rounded-lg bg-white">
				<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :style="medOrgs.length > 9 ? 'height: calc(100vh - 316px)' : ''">
					<table :style="medOrgs.length > 0 ? 'min-height: 550px' : ''">
						<thead>
							<!-- Заголовки -->
							<tr :class="{ tr1__sticky: rowsVisible > 5 }">
								<th
									style="min-width: 50px"
									class="sticky-table__header2 w-full text-center border-b border-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								>
									П/П
								</th>
								<th
									v-for="(item, key) in fields"
									:key="'A' + key"
									class="table-cell th-content sm:relative bg-white"
									:class="{ 'td-name td-name-head z-20': key === 0, 'border-b sm:border-gray-100': key !== 0 }"
								>
									<p class="w-full my-auto text-gray-400 cursor-pointer font-normal flex justify-start items-center" @click="switchFilterVisibility(key)">
										<span class="underline" :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }">
											{{ item.Name }}
										</span>
										<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
										<!-- eslint-disable max-len -->
										<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
												fill="#9193A5"
											/>
										</svg>
										<!-- eslint-enable max-len -->
									</p>
									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:filterOpen="filterOpen"
										:position="key === 0 ? 'left' : 'right'"
										:id-filter="key"
										:param="params"
										@query="getData"
										:fields="fields"
										:defaultSortField="defaultSort.field"
										:defaultSortOrder="defaultSort.order"
										:item="item"
									/>
								</th>
							</tr>
							<!-- /Заголовки -->
						</thead>

						<!-- Содержимое -->
						<tbody v-if="medOrgs.length > 0">
							<template v-for="(item, index) in medOrgs">
								<tr
									:key="item.id"
									class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500"
									:class="getRowClass(item, item.id === opened, false)"
									@click="openRow(item)"
								>
									<td
										class="align-middle sticky-table__row2 px-2 text-center text-sm font-normal whitespace-no-wrap border-b border-gray-100 leading-5 text-gray-500"
										:class="getRowClass(item, item.id === opened, true)"
									>
										<span v-if="params.currentPage === 0">
											{{ index + 1 }}
										</span>
										<span v-if="params.currentPage > 0">
											{{ params.currentPage * rowsVisible + (index + 1) }}
										</span>
									</td>
									<td class="align-middle table-cell border-t border-gray-100 td-name" :class="getRowClass(item, item.id === opened, true)">
										<div class="flex items-center mb-2">
											<div v-if="item.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
											<div v-if="item.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
										</div>
										<div class="flex">
											<Icons
												v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
												class="icon-control mr-2"
												icon-name="pen"
												@click.native.stop="openEditDialog(item)"
												title="Редактировать"
											/>
											<Icons
												v-if="userIsInRoles(['Support', 'MZ_Employee'])"
												class="icon-control icon-danger mr-2"
												icon-name="trash"
												@click.native.stop="openDeleteDialog(item, index)"
												title="Удалить"
											/>
											<p :class="{ 'underline cursor-pointer': item.hasBranch }">{{ item.name }}</p>
										</div>
									</td>
									<td class="align-middle table-cell border-b border-gray-100 td-one">
										<p>{{ item.shortName ? item.shortName : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.address ? item.address : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.postAddress ? item.postAddress : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.ownerShipId ? setName(item.ownerShipId, 'ownership') : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.inn ? item.inn : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.webSiteAddress ? item.webSiteAddress : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.departmentBelongId ? setName(item.departmentBelongId, 'departments') : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.nomenclatureId ? setName(item.nomenclatureId, 'nomenclatures') : '---' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.noteName }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<span v-if="item.years">
											{{
												item.years
													.map((item) => {
														return item.year;
													})
													.join(', ')
											}}
										</span>
										<span v-else> - </span>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.ambulator ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.stacionar ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.healthResort ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p>{{ item.psychiatric ? 'Да' : 'Нет' }}</p>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p v-if="item.noteId === Note.Participation || item.noteId === Note.Federal">
											<a href="#" @click="openBunnersPopUp(item)">Получить баннер</a>
										</p>
										<span v-else> - </span>
									</td>
									<td class="align-middle table-cell border-b border-gray-100">
										<p v-if="item.noteId === Note.Participation || item.noteId === Note.Federal">
											<a href="#" @click="downloadQr(item)">Скачать</a>
										</p>
										<span v-else> - </span>
									</td>
								</tr>
								<template v-if="item.id === opened">
									<tr
										v-for="(subitem, subindex) in item.branches"
										:key="subitem.id"
										class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500"
										:class="getRowClass(subitem, item.id === opened, false)"
									>
										<td class="table-cell border-t border-gray-100 sticky-table__row2 z-10" :class="getRowClass(subitem, item.id === opened, true)">
											{{ subindex + 1 }}
										</td>
										<td class="table-cell border-t border-gray-100 td-name" :class="getRowClass(subitem, item.id === opened, true)">
											<div class="flex items-center mb-2">
												<div v-if="subitem.isBranch" class="filial-or-secondary-default filial-bg">Филиал</div>
												<div v-if="subitem.isClone" class="filial-or-secondary-default secondary-bg ml-2">Вторичная МО</div>
											</div>
											<div class="flex">
												<Icons
													v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator']) && !subitem.isClone"
													class="icon-control mr-2"
													icon-name="pen"
													@click.native.stop="openEditDialog(subitem)"
													title="Редактировать"
												/>
												<Icons
													v-if="userIsInRoles(['Support', 'MZ_Employee'])"
													class="icon-control icon-danger mr-2"
													icon-name="trash"
													@click.native.stop="openDeleteDialog(subitem, subindex)"
													title="Удалить"
												/>
												<p>{{ subitem.name }}</p>
											</div>
										</td>
										<td class="table-cell border-b border-gray-100 td-one">
											<p>{{ subitem.shortName ? subitem.shortName : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.address ? subitem.address : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.postAddress ? subitem.postAddress : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.ownerShipId ? setName(subitem.ownerShipId, 'ownership') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.inn ? subitem.inn : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.webSiteAddress ? subitem.webSiteAddress : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.departmentBelongId ? setName(subitem.departmentBelongId, 'departments') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.nomenclatureId ? setName(subitem.nomenclatureId, 'nomenclatures') : '---' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.noteName }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<span v-if="subitem.years">
												{{
													subitem.years
														.map((subitem) => {
															return subitem.year;
														})
														.join(', ')
												}}
											</span>
											<span v-else> - </span>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.ambulator ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.stacionar ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.healthResort ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p>{{ subitem.psychiatric ? 'Да' : 'Нет' }}</p>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p v-if="subitem.noteId === Note.Participation || subitem.noteId === Note.Federal || !subitem.isClone">
												<a href="#" @click="openBunnersPopUp(subitem)">Получить баннер</a>
											</p>
											<span v-else> - </span>
										</td>
										<td class="table-cell border-b border-gray-100">
											<p v-if="subitem.noteId === Note.Participation || subitem.noteId === Note.Federal || !subitem.isClone">
												<a href="#" @click="downloadQr(subitem)">Скачать</a>
											</p>
											<span v-else> - </span>
										</td>
									</tr>
								</template>
							</template>
						</tbody>
					</table>
					<div v-if="!loadingTable && medOrgs.length === 0" class="flex items-center w-full h-96 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">
						<span class="w-full">Данных нет...</span>
					</div>
				</div>
				<div class="hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
					<div class="mr-auto">
						<p>Всего записей: {{ totalRows }}</p>
					</div>
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 50 }">50</li>
							<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 100 }">100</li>
							<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 200 }">200</li>
							<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == null }">Все</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
				<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
		</div>
		<PopUp :open="open" :title="popupTitle" size="70%" @close="closePopup()">
			<div class="flex w-full gap-5">
				<div class="w-6/12">
					<div class="input-box">
						<label>Регион*</label>
						<v-select
							:options="regions"
							:disabled="innEmpty || infoMO.isClone || regionDisabled"
							v-model="infoMO.regionId"
							label="name"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.regionId.val
							}"
						/>
						<p class="error-text" v-if="error.regionId.val">
							{{ error.regionId.msg }}
						</p>
					</div>
					<TextInput
						label="Краткое наименование МО*"
						:error="error.shortName.val ? error.shortName.msg : null"
						:title="infoMO.shortName"
						:disabled="innEmpty || infoMO.isClone"
						v-model="infoMO.shortName"
					/>
					<TextInput
						label="Почтовый адрес*"
						:error="error.postAddress.val ? error.postAddress.msg : null"
						:title="infoMO.postAddress"
						:disabled="innEmpty || infoMO.isClone"
						v-model="infoMO.postAddress"
					/>
					<TextInput label="ИНН*" :error="error.inn.val ? error.inn.msg : null" :title="infoMO.inn" @input="setInn(infoMO.inn)" type="number" v-model="infoMO.inn" />
					<div class="input-box" v-if="userIsInRoles(['Support', 'MZ_Employee'])">
						<label>Ведомственная принадлежность</label>
						<v-select :options="departments" :disabled="innEmpty || infoMO.isClone" label="name" v-model="infoMO.departmentBelongId" :reduce="(option) => option.value"></v-select>
					</div>
					<div class="input-box" v-if="infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal">
						<label class="select-label"> Включено в перечень </label>
						<multiselect
							tag-placeholder=""
							:disabled="innEmpty || !infoMO.isMain"
							@change="setYears(infoMO.years)"
							placeholder=""
							:hide-selected="true"
							:searchable="false"
							:multiple="true"
							:options="optionsYears"
							label="year"
							track-by="year"
							v-model="infoMO.years"
						/>
					</div>
					<div class="flex justify-start mt-4" v-if="infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal">
						<div class="radio-group" style="width: 240px">
							<p class="select-label">Амбулаторные*</p>
							<div class="flex items-center">
								<input type="radio" name="ambulator" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.ambulator" />
								<label class="field-value">Да</label>
								<input type="radio" name="ambulator" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.ambulator" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Стационарные*</p>
							<div class="flex items-center">
								<input type="radio" name="stacionar" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.stacionar" />
								<label class="field-value">Да</label>
								<input type="radio" name="stacionar" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.stacionar" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
					</div>
					<div class="flex justify-start" v-if="infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal">
						<div class="radio-group" style="width: 240px">
							<p class="select-label">Психиатрические*</p>
							<div class="flex items-center">
								<input type="radio" name="psychiatric" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.psychiatric" />
								<label class="field-value">Да</label>
								<input type="radio" name="psychiatric" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.psychiatric" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
						<div class="radio-group">
							<p class="select-label">Санаторно-курортные*</p>
							<div class="flex items-center">
								<input type="radio" name="healthResort" :disabled="innEmpty || infoMO.isClone" :value="true" v-model="infoMO.healthResort" />
								<label class="field-value">Да</label>
								<input type="radio" name="healthResort" :disabled="innEmpty || infoMO.isClone" :value="false" v-model="infoMO.healthResort" class="ml-2" />
								<label class="field-value">Нет</label>
							</div>
						</div>
					</div>
					<div v-if="(infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal) && error.conditions.val" class="error-text">
						{{ error.conditions.msg }}
					</div>
				</div>
				<div class="w-6/12">
					<TextInput label="Полное наименование МО*" :error="error.name.val ? error.name.msg : null" :title="infoMO.name" :disabled="innEmpty || infoMO.isClone" v-model="infoMO.name" />
					<TextInput
						:label="'Фактический адрес' + (!infoMO.isClone ? '*' : '')"
						:error="error.address.val ? error.address.msg : null"
						:title="infoMO.address"
						:disabled="innEmpty"
						v-model="infoMO.address"
					/>
					<div class="input-box">
						<label>Форма собственности*</label>
						<v-select
							:options="ownership"
							:disabled="innEmpty || infoMO.isClone"
							:error="ownershipError"
							@input="ownershipOGV(infoMO.ownerShipId)"
							v-model="infoMO.ownerShipId"
							label="name"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.ownerShipId.val
							}"
						/>
						<p class="error-text" v-if="error.ownerShipId.val">
							{{ error.ownerShipId.msg }}
						</p>
						<p v-if="ownershipError" class="error-text">{{ ownershipError }}</p>
					</div>
					<TextInput
						label="Адрес официального сайта*"
						:error="error.webSiteAddress.val ? error.webSiteAddress.msg : null"
						:disabled="innEmpty || infoMO.isClone"
						v-model="infoMO.webSiteAddress"
					/>
					<div class="input-box">
						<label>Номенклатура*</label>
						<v-select
							:options="nomenclatures"
							:disabled="innEmpty || infoMO.isClone"
							@input="notesChange(infoMO.nomenclatureId)"
							v-model="infoMO.nomenclatureId"
							label="name"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.nomenclatureId.val
							}"
						/>
						<p class="error-text" v-if="error.nomenclatureId.val">
							{{ error.nomenclatureId.msg }}
						</p>
					</div>
					<div class="input-box">
						<label>Примечание*</label>
						<v-select
							:options="notes"
							:disabled="innEmpty || !infoMO.isMain || noteDisabled"
							v-model="infoMO.noteId"
							label="name"
							:reduce="(option) => option.value"
							:class="{
								'custom-error': error.noteId.val
							}"
						/>
						<p class="error-text" v-if="error.noteId.val">
							{{ error.noteId.msg }}
						</p>
					</div>
					<div class="static-row mt-4">
						<label>Тип МО: </label>
						<span class="field-value">{{ infoMO.typeName }}</span>
					</div>
					<div v-if="infoMO.parentName != null" class="static-row">
						<label>Головная организация: </label>
						<span class="field-value">{{ infoMO.parentName }}</span>
					</div>
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" :disabled="!!ownershipError" text="Сохранить" @click.native="validation(infoMO)" />
			</div>
		</PopUp>
		<PopUp :open="openBunners" size="95%" title="Получить баннер" @close="closeBunners()">
			<div class="flex justify-between">
				<Bunner :ids="bunnersId" level="mo" type="1"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="2"></Bunner>
				<Bunner :ids="bunnersId" level="mo" type="3"></Bunner>
			</div>
		</PopUp>
		<PopUp :open="branchDialog" @close="setBranch(true)">
			<p>
				МО с таким ИНН уже существует. Создать еще одно МО с тем же ИНН можно в двух случаях: если оно является филиалом медицинского учреждения, то выберите ниже вариант «Филиал», если
				учреждение обслуживает пациентов из другого региона (отличного от региона, в котором находится учреждение), то выберите вариант «МО обслуживает другой регион»
			</p>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" text="Филиал" @click.native="setBranch(true)" />
				<Button size="medium" text="МО обслуживает другой регион" @click.native="setBranch(false)" />
			</div>
		</PopUp>
		<PopUp :open="headExistsDialog" @close="headExistsDialog = false">
			<p>В Системе уже существует головная организация с таким ИНН.</p>
			<div class="flex mt-5">
				<Button size="smoll" color="white" text="Закрыть" @click.native="headExistsDialog = false" />
			</div>
		</PopUp>
		<PopUp :open="openExport" size="500px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col overflow-auto">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import FilterComponent from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';
import PopUp from '@/components/PopUp.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import Button from '@/components/Button.vue';
import Bunner from '@/components/Bunner.vue';
import TextInput from '@/components/TextInput.vue';
import loadingComponent from '@/components/Loading.vue';
import { getters, actions, mutations, methods, compareAsc } from '@/store/store';
import { Note } from '@/store/enumerations';
// Локальные данные стора
import localActions from '../store/actions';

export default {
	name: 'TableMain',
	components: {
		loadingComponent,
		Icons,
		Bunner,
		FilterComponent,
		Multiselect,
		Button,
		PopUp,
		TextInput,
		CheckboxGroup
	},
	data() {
		return {
			openExport: false,
			open: false,
			branchDialog: false,
			headExistsDialog: false,
			openBunners: false,
			bunnersId: '',
			rowsVisible: this.$DefaultPageSize,
			totalRows: 0,
			totalCount: 0,
			page: 0,
			regions: [],
			infoMO: {},
			originalMO: null,
			error: {},
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Полное наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Краткое наименование МО',
					fieldName: 'shortName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'shortName',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Фактический адрес',
					fieldName: 'address',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Почтовый адрес',
					fieldName: 'postAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Форма собственности',
					fieldName: 'OwnerShipName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'ИНН',
					fieldName: 'inn',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Адрес официального сайта',
					fieldName: 'webSiteAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Ведомственная принадлежность',
					fieldName: 'DepartmentBelongName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Номенклатура',
					fieldName: 'NomenclatureName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Примечание',
					fieldName: 'NoteName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'name',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Включено в перечень',
					fieldName: 'YearStr',
					filterType: 9,
					filterValueType: 1,
					value1: null,
					value2: null,
					values: [],
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulator',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionar',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'healthResort',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatric',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Баннер',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'QR-код',
					filterType: 0,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			fieldsDownloadExcell: [
				{
					title: 'Филиал',
					id: 'Branch',
					checked: true
				},
				{
					title: 'Полное наименование МО',
					id: 'Name',
					checked: true
				},
				{
					title: 'Краткое наименование МО',
					id: 'ShortName',
					checked: true
				},
				{
					title: 'Фактический адрес',
					id: 'Address',
					checked: true
				},
				{
					title: 'Почтовый адрес',
					id: 'PostAddress',
					checked: true
				},
				{
					title: 'Форма собственности',
					id: 'OwnershipForm',
					checked: true
				},
				{
					title: 'ИНН',
					id: 'INN',
					checked: true
				},
				{
					title: 'Адрес официального сайта',
					id: 'WebsiteAddress',
					checked: true
				},
				{
					title: 'Ведомственная принадлежность',
					id: 'Department',
					checked: true
				},
				{
					title: 'Номенклатура',
					id: 'Nomenclature',
					checked: true
				},
				{
					title: 'Примечание',
					id: 'Note',
					checked: true
				},
				{
					title: 'Включено в перечень',
					id: 'Year',
					checked: true
				},
				{
					title: 'Амбулаторные',
					id: 'Ambulatory',
					checked: true
				},
				{
					title: 'Стационарные',
					id: 'Stationary',
					checked: true
				},
				{
					title: 'Санаторно-курортные',
					id: 'Sanatorium',
					checked: true
				},
				{
					title: 'Психиатрические',
					id: 'Psychiatric',
					checked: true
				}
			],
			allRows: [],
			medOrgs: [],
			opened: null,
			optionsYears: [],
			children: [],
			upadte: false,
			popupTitle: '',
			defaultSort: {
				field: 'NoteName',
				order: 1
			},
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: '1038b985-5708-41ef-95cb-85ba7586702c'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: 2
				},
				pageSize: this.$MaxPageSize,
				currentPage: 0
			},
			loadToExcell: false,
			departments: [],
			nomenclatures: [],
			notes: [],
			ownership: [],
			ownershipError: null,
			noteDisabled: true,
			innEmpty: true,
			regionDisabled: false,
			defNomenclatures: [
				'Женская консультация',
				'Молочная кухня',
				'Станция скорой медицинской помощи',
				'Станция переливания крови (центр крови)',
				'Дом ребенка, в том числе специализированный',
				'Физиотерапевтическая поликлиника',
				'Центр медицинской и социальной реабилитации, в том числе с отделением постоянного проживания инвалидов и детей-инвалидов с тяжелыми формами детского церебрального паралича, самостоятельно не передвигающихся и себя не обслуживающих',
				'Психиатрическая больница (стационар) специализированного типа',
				'Психиатрическая больница (стационар) специализированного типа с интенсивным наблюдением',
				'Клинико-диагностическая лаборатория',
				'Бактериологическая больница, в том числе по диагностике туберкулеза',
				'Центр медицинской профилактики',
				'Центр медицины катастроф',
				'Центр медицинских мобилизационных резервов «Резерв»',
				'Медицинский информационно-аналитический центр',
				'Медицинский биофизический центр',
				'Центр военно-врачебной экспертизы',
				'Центр судебно-медицинской экспертизы',
				'Бюро медико-социальной экспертизы',
				'Бюро медицинской статистики',
				'Патологоанатомическое бюро',
				'Бюро судебно-медицинской экспертизы',
				'Центр гигиены и эпидемиологии',
				'Противочумный центр (станция)',
				'Дезинфекционный центр (станция)',
				'Центр гигиенического образования населения',
				'Центр государственного санитарно-эпидемиологического надзора'
			],
			loadingTable: false
		};
	},
	watch: {
		'infoMO.name': function (newVal, oldVal) {
			// console.log(newVal, oldVal);
			if (newVal && oldVal) {
				let shortened = this.shortenName(newVal);
				if (shortened !== newVal) {
					this.infoMO.shortName = shortened;
				}
			}
		}
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		...localActions,
		allClose() {
			this.closePopup();
			this.branchDialog = false;
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		setBranch(branch) {
			this.infoMO.isBranch = branch;
			this.infoMO.isClone = !branch;
			this.infoMO.typeName = branch ? 'Филиал' : 'Вторичная МО';
			this.branchDialog = false;

			if (!branch) {
				let beyondTheRegionNote = this.notes.find((x) => x.value === Note.BeyondTheRegion);

				if (beyondTheRegionNote !== undefined) {
					this.infoMO.noteId = beyondTheRegionNote.value;
					this.infoMO.years = [];
				}
			}
		},
		setInn(inn) {
			if (inn.length >= 10) {
				this.getMedicalOrgsByINN(inn).then((res) => {
					let mainMO = res.data.find((x) => x.isMain);

					if (mainMO) {
						if (this.originalMO && this.originalMO.isMain && this.originalMO.hasBranch) {
							this.openEditDialog(this.originalMO);
							this.headExistsDialog = true;
						} else {
							this.setMainMO(mainMO);
							this.innEmpty = false;
							this.branchDialog = true;
						}
					} else {
						let currentId = this.infoMO.id;
						this.resetForm();
						this.infoMO.id = currentId;
						this.infoMO.inn = inn;
						this.innEmpty = false;
					}
				});
			} else {
				let currentId = this.infoMO.id;
				this.resetForm();
				this.infoMO.id = currentId;
				this.infoMO.inn = inn;
			}
		},
		setMainMO(mainMO) {
			let moId = this.infoMO.id;
			Object.assign(this.infoMO, mainMO);
			this.infoMO.id = moId;
			this.infoMO.oldId = 0;
			this.infoMO.typeName = null;
			this.infoMO.parentId = mainMO.id;
			this.infoMO.parentName = mainMO.name;
			this.infoMO.isMain = false;
			this.infoMO.regionId = this.region.id;
			this.infoMO.address = null;
		},
		setName(id, type) {
			let name = '';
			this[type].forEach((item) => {
				if (item.value === id) name = item.name;
			});
			return name;
		},
		openConsole(text) {
			console.log(text);
		},
		ownershipOGV(id) {
			this.ownershipError = null;
			if (id === '8da58370-82e4-4092-9210-95b9ef49fef7' && !this.userIsInRoles(['Support', 'MZ_Employee'])) {
				this.ownershipError = 'Федеральные организации может создавать только сотрудник Минздрава!';
			}
		},
		notesChange(id) {
			this.noteDisabled = false;
			this.nomenclatures.forEach((item) => {
				if (item.value === id) {
					this.defNomenclatures.forEach((name) => {
						if (name === item.name) {
							this.infoMO.noteId = Note.NoParticipationByOrder;
							this.noteDisabled = true;
						}
					});
				}
			});
		},
		openPopUp() {
			this.infoMO.regionId = this.region.id;
			this.update = false;
			this.popupTitle = 'Добавить МО';
			this.open = true;
		},
		openEditDialog(item) {
			this.originalMO = item;
			Object.assign(this.infoMO, item);

			if (this.infoMO.parentId !== null) {
				this.infoMO.typeName = this.infoMO.isBranch ? 'Филиал' : 'Вторичная МО';
				let parent = this.medOrgs.find((x) => x.id === this.infoMO.parentId);
				this.infoMO.parentName = parent ? parent.name : null;
			}

			if (item.inn) {
				this.innEmpty = false;
			} else {
				this.innEmpty = true;
			}

			this.notesChange(item.nomenclatureId);
			this.popupTitle = 'Редактировать МО';
			this.open = true;
			this.update = true;
		},
		sendEmail(login) {
			let yea = confirm('Отправить подтверждение на E-mail?');
			if (yea) {
				this.sendEmailConfirmedCode(login);
			}
			/* eslint-enable */
		},
		closePopup() {
			this.open = false;
			this.ownershipError = null;

			this.resetForm();
			this.resetFormErrors();
		},
		resetForm() {
			this.innEmpty = true;

			this.infoMO = {
				name: null,
				regionId: this.region.id,
				shortName: null,
				address: null,
				postAddress: null,
				ownerShipId: null,
				inn: null,
				isMain: true,
				isBranch: false,
				isClone: false,
				typeName: 'Головная МО',
				webSiteAddress: null,
				departmentBelongId: null,
				nomenclatureId: null,
				noteId: null,
				ambulator: false,
				stacionar: false,
				healthResort: false,
				psychiatric: false,
				parentId: null,
				parentName: null,
				yearStr: null,
				years: []
			};

			this.originalMO = null;
		},
		resetFormErrors() {
			this.error = {
				name: { val: null, msg: 'Введите полное наименование МО' },
				regionId: { val: null, msg: 'Выберите Регион' },
				shortName: { val: null, msg: 'Введите краткое наименование МО' },
				address: { val: null, msg: 'Введите фактический адрес' },
				postAddress: { val: null, msg: 'Введите почтовый адрес' },
				ownerShipId: { val: null, msg: 'Выберите форма собственности' },
				inn: { val: null, msg: 'Введите ИНН' },
				webSiteAddress: { val: null, msg: 'Введите адрес официального сайта' },
				nomenclatureId: { val: null, msg: 'Выберите номенклатуру' },
				noteId: { val: null, msg: 'Выберите примечание' },
				ambulator: { val: null, msg: 'Выберите амбулаторные условия или нет' },
				stacionar: { val: null, msg: 'Выберите стационарные условия или нет ' },
				healthResort: { val: null, msg: 'Выберите санаторно-курортные условия или нет' },
				psychiatric: { val: null, msg: 'Выберите психиатрические условия или нет' },
				conditions: { val: null, msg: 'Установите значение "Да" хотя бы в одном из условий оказания медицинской помощи' }
			};
		},
		getRowClass(item, opened, cell) {
			let result = '';

			if (item.noteId === Note.Reorganized) {
				result += 'bg-aquamarine';
			} else if (item.noteId === Note.Excluded) {
				result += 'bg-lilac';
			} else if (item.noteId === Note.NoTariffAgreement) {
				result += 'bg-rich-pink';
			} else if (item.noteId === Note.NoMedicalCare) {
				result += 'bg-light-green';
			} else if (item.noteId === Note.NoParticipationByOrder) {
				result += 'bg-lemon-yellow';
			} else {
				result += 'bg-white';
			}

			if (opened) {
				if (result.search('bg-white') !== -1) {
					result = result.replace('bg-white', 'bg-gray-30');
				} else if (!cell) {
					result += ' bg-dark-10';
				}
			}

			return result;
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		validation(infoMO) {
			if (infoMO.inn === null || infoMO.inn.length < 10) {
				this.error.inn.val = true;
				return;
			}

			this.resetFormErrors();

			for (const key in infoMO) {
				if (Object.hasOwnProperty.call(infoMO, key)) {
					if (
						key === 'name' ||
						key === 'regionId' ||
						key === 'shortName' ||
						key === 'address' ||
						key === 'postAddress' ||
						key === 'ownerShipId' ||
						key === 'inn' ||
						key === 'webSiteAddress' ||
						key === 'nomenclatureId' ||
						key === 'noteId' ||
						key === 'ambulator' ||
						key === 'stacionar' ||
						key === 'healthResort' ||
						key === 'psychiatric'
					) {
						if (infoMO[key] === null || infoMO[key].length === 0) {
							if (key === 'address' && infoMO.isClone) {
								this.error[key].val = null;
							} else {
								this.error[key].val = true;
							}
						}
					}
				}
			}

			if ((infoMO.noteId === Note.Participation || infoMO.noteId === Note.Federal) && !infoMO.ambulator && !infoMO.stacionar && !infoMO.healthResort && !infoMO.psychiatric) {
				this.error.conditions.val = true;
			}

			let errors = Object.values(this.error).some((item) => {
				// if (item.val !== null) console.log('Object.values(this.error).some((item)', item.msg, item.val);
				return item.val !== null;
			});

			if (!errors) {
				// console.log('good', this.error);
				this.sendForm();
			}
		},
		sendForm() {
			if (!this.ownershipError) {
				if (!this.update) {
					this.addMO(this.infoMO).then((res) => {
						this.infoMO.id = res.data.id;

						let addedMO = {};
						Object.assign(addedMO, this.infoMO);

						if (this.infoMO.isMain) {
							this.medOrgs.unshift(addedMO);
							this.allRows.unshift(addedMO);
						} else {
							let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

							if (mainMO) {
								mainMO.hasBranch = true;

								if (mainMO.branches === undefined || mainMO.branches === null) {
									mainMO.branches = [];
								}

								if (this.opened !== mainMO.id) {
									this.openRow(mainMO);
								}

								mainMO.branches.unshift(addedMO);
							}
						}

						this.closePopup();
					});
				} else {
					this.updateMO(this.infoMO).then((res) => {
						if (res.errors) {
							console.log(res.errors);
						} else {
							let updatedMO = null;

							if (this.infoMO.isMain) {
								updatedMO = this.medOrgs.find((x) => x.id === this.infoMO.id);
								this.loadBranches(updatedMO);
							} else {
								let mainMO = this.medOrgs.find((x) => x.id === this.infoMO.parentId);

								if (mainMO) {
									updatedMO = mainMO.branches.find((x) => x.id === this.infoMO.id);
								} else {
									updatedMO = this.medOrgs.find((x) => x.id === this.infoMO.id);
								}
							}

							if (updatedMO) {
								Object.assign(updatedMO, this.infoMO);
								updatedMO.hasBranch = this.infoMO.hasBranch;
							}

							this.closePopup();
						}
					});
				}
			}
		},
		loadBranches(medOrg) {
			let params = {
				filters: [
					{
						fieldName: 'ParentId',
						filterType: 1,
						filterValueType: 1,
						value1: medOrg.id
					}
				]
			};

			this.getMedicalOrgs(params).then((result) => {
				medOrg.branches = result.data.data;
			});
		},
		openDeleteDialog(item, index) {
			if (confirm('Вы уверены, что хотите удалить?')) {
				this.deleteMO(item.id)
					.then((result) => {
						if (item.isMain) {
							this.medOrgs.splice(index, 1);

							let rowIndex = this.allRows.findIndex((x) => x.id === item.id);
							this.allRows.splice(rowIndex, 1);
						} else {
							let mainMO = this.medOrgs.find((x) => x.id === item.parentId);

							if (mainMO) {
								mainMO.branches.splice(index, 1);

								if (mainMO.branches.length === 0) {
									mainMO.hasBranch = false;
								}
							}
						}

						this.notify('Медицинская организация успешно удалена!', 'success');
					})
					.catch((error) => {
						this.notify('Ошибка при удалении медицинской организации, обратитесь к Администратору!', 'error');
					});
			}
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.getData(this.params, true);
		},
		openBunnersPopUp(item) {
			if (item.oldId) {
				this.bunnersId = item.oldId;
			} else {
				this.bunnersId = item.id;
			}
			this.openBunners = true;
		},
		closeBunners() {
			this.bunnersId = '';
			this.openBunners = false;
		},
		updatePage(input) {
			this.rowsVisible = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, resetPage) {
			if (resetPage) {
				this.params.currentPage = 0;
				this.page = 0;

				this.addManualSearchByName(this.params.filters);

				this.loadingTable = true;

				this.getMedicalOrgs(this.params).then((res) => {
					this.allRows = res.data.data;
					this.medOrgs = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
					this.fields[0].list = res.data.searchData;
					this.fields[1].list = res.data.searchData;

					this.allRows.map((item) => {
						item.active = false;
						return item;
					});

					this.totalRows = res.data.totalRecords;
					this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
					this.loadingTable = false;
				});
			} else {
				this.medOrgs = this.getTablePage(this.allRows, this.params.currentPage, this.rowsVisible);
			}
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'MedicalOrgs/GetMedicalOrgsExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Медицинские организации.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		},
		downloadQr(item) {
			let type = 'ambulator';
			if (item.ambulator) {
				type = 'ambulator';
			} else if (item.stacionar) {
				type = 'stacionar';
			} else if (item.healthResort) {
				type = 'healthResort';
			} else {
				type = 'psychiatric';
			}
			this.getQr(item.id, type).then((res) => {
				let fileNameAndType = decodeURI('QR.png');

				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileNameAndType);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
		},
		shortenName(name) {
			let regex = /Федеральное государственное образовательное учреждение высшего профессионального образования/i;
			name = name.replace(regex, 'ФГОУ ВПО');
			regex = /Федеральное государственное автономное образовательное учреждение высшего образования/i;
			name = name.replace(regex, 'ФГАОУ ВО');
			regex = /Федеральное государственное бюджетное образовательное учреждение высшего образования/i;
			name = name.replace(regex, 'ФГБОУ ВО');
			regex = /САНКТ-ПЕТЕРБУРГСКОЕ ГОСУДАРСТВЕННОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ЗДРАВООХРАНЕНИЯ/i;
			name = name.replace(regex, 'СПб ГБУЗ');
			regex = /Республиканское государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'РГБУЗ');
			regex = /Федеральное государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГБУЗ');
			regex = /Федеральное государственное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГКУЗ');
			regex = /Федеральное государственное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГКУЗ');
			regex = /Областное государственное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГАУЗ');
			regex = /осударственное областное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГОБУЗ');
			regex = /Областное государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГБУЗ');
			regex = /Областное государственное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГКУЗ');
			regex = /Областное государственное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОГКУЗ');
			regex = /Краевое государственное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'КГАУЗ');
			regex = /Краевое государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'КГБУЗ');
			regex = /Федеральное государственное бюджетное научное учреждение/i;
			name = name.replace(regex, 'ФГБНУ');
			regex = /Федеральное государственное бюджетное учреждение науки/i;
			name = name.replace(regex, 'ФГБУН');
			regex = /Федеральное государственное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФГУЗ');
			regex = /Государственное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГАУЗ');
			regex = /Государственное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГБУЗ');
			regex = /Государственное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГКУЗ');
			regex = /Государственное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ГКУЗ');
			regex = /Муниципальное автономное учреждение здравоохранения/i;
			name = name.replace(regex, 'МАУЗ');
			regex = /Муниципальное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'МБУЗ');
			regex = /Федеральное государственное унитарное предприятие/i;
			name = name.replace(regex, 'ФГУП');
			regex = /Федеральное государственное автономное учреждение/i;
			name = name.replace(regex, 'ФГАУ');
			regex = /Федеральное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФБУЗ');
			regex = /Федеральное государственное бюджетное учреждение/i;
			name = name.replace(regex, 'ФГБУ');
			regex = /Федеральное государственное казенное учреждение/i;
			name = name.replace(regex, 'ФГКУ');
			regex = /Федеральное государственное казённое учреждение/i;
			name = name.replace(regex, 'ФГКУ');
			regex = /Федеральное казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'ФКУЗ');
			regex = /Федеральное казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'ФКУЗ');
			regex = /Областное бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'ОБУЗ');
			regex = /Государственное областное казенное учреждение/i;
			name = name.replace(regex, 'ГОКУ');
			regex = /Государственное областное казённое учреждение/i;
			name = name.replace(regex, 'ГОКУ');
			regex = /Негосударственное учреждение здравоохранения/i;
			name = name.replace(regex, 'НУЗ');
			regex = /Краевое государственное бюджетное учреждение/i;
			name = name.replace(regex, 'КГБУ');
			regex = /Государственное учреждение здравоохранения/i;
			name = name.replace(regex, 'ГУЗ');
			regex = /Муниципальное учреждение здравоохранения/i;
			name = name.replace(regex, 'МУЗ');
			regex = /Общество с ограниченной ответственностью/i;
			name = name.replace(regex, 'ООО');
			regex = /Федеральное бюджетное учреждение науки/i;
			name = name.replace(regex, 'ФБУН');
			regex = /Федеральное государственное учреждение/i;
			name = name.replace(regex, 'ФГУ');
			regex = /Автономная некоммерческая организация/i;
			name = name.replace(regex, 'АНО');
			regex = /Государственное бюджетное учреждение/i;
			name = name.replace(regex, 'ГБУ');
			regex = /Бюджетное учреждение здравоохранения/i;
			name = name.replace(regex, 'БУЗ');
			regex = /Казенное учреждение здравоохранения/i;
			name = name.replace(regex, 'КУЗ');
			regex = /Казённое учреждение здравоохранения/i;
			name = name.replace(regex, 'КУЗ');
			regex = /Муниципальное автономное учреждение/i;
			name = name.replace(regex, 'МАУ');
			regex = /Муниципальное унитарное предприятие/i;
			name = name.replace(regex, 'МУП');
			regex = /Государственное казенное учреждение/i;
			name = name.replace(regex, 'ГКУ');
			regex = /Государственное казённое учреждение/i;
			name = name.replace(regex, 'ГКУ');
			regex = /Муниципальное бюджетное учреждение/i;
			name = name.replace(regex, 'МБУ');
			regex = /Федеральное бюджетное учреждение/i;
			name = name.replace(regex, 'ФБУ');
			regex = /Закрытое акционерное общество/i;
			name = name.replace(regex, 'ЗАО');
			regex = /Открытое акционерное общество/i;
			name = name.replace(regex, 'ОАО');
			regex = /Государственное учреждение/i;
			name = name.replace(regex, 'ГУ');
			regex = /Бюджетное учреждение/i;
			name = name.replace(regex, 'БУ');
			regex = /Акционерное общество/i;
			name = name.replace(regex, 'АО');
			regex = /Казенное учреждение/i;
			name = name.replace(regex, 'КУ');
			regex = /Казённое учреждение/i;
			name = name.replace(regex, 'КУ');

			return name;
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		}
	},
	created() {
		this.Note = Note;
		this.resetFormErrors();
	},
	mounted() {
		this.resetForm();

		this.params.filters[0].value1 = this.region.id;
		this.params.sortOption.fieldName = this.defaultSort.field;
		this.params.sortOption.sortType = this.defaultSort.order;

		this.getData(this.params, true);

		let yearFilterOptions = [];

		this.getRegionYearData(this.region.id).then((res) => {
			for (let i = 0; i < res.data.availableYears.length; i += 1) {
				this.optionsYears.push({ year: res.data.availableYears[i] });
				yearFilterOptions.push({ name: res.data.availableYears[i].toString(), checked: false });
			}

			for (let j = 0; j < res.data.closedYears.length; j += 1) {
				for (let l = 0; l < this.optionsYears.length; l += 1) {
					if (res.data.closedYears[j] === this.optionsYears[l].year) {
						this.optionsYears[l].$isDisabled = true;
					}
				}
			}
		});

		let yearField = this.fields.find((x) => x.fieldName === 'YearStr');

		if (yearField !== undefined) {
			yearField.filterOptions = yearFilterOptions;
		}

		if (!this.userIsInRoles(['Support', 'MZ_Employee'])) {
			this.infoMO.regionId = this.region.id;
			this.regionDisabled = true;
		}

		this.getRegions().then((res) => {
			this.regions = [];
			res.data.forEach((item) => {
				this.regions.push({ name: item.Name, value: item.Id });
			});
		});

		this.getDepartmentBelong().then((res) => {
			this.departments = [];
			res.data.forEach((item) => {
				this.departments.push({ name: item.name, value: item.Id });
			});
		});

		this.getNomenclatures().then((res) => {
			this.fields[8].list = res.data;
			this.nomenclatures = [];
			res.data.forEach((item) => {
				this.nomenclatures.push({ name: item.name, value: item.Id });
			});
			this.nomenclatures.sort((a, b) => compareAsc(a.name, b.name));
		});

		this.getNotes().then((res) => {
			this.fields[9].list = res.data;
			this.notes = [];
			res.data.forEach((item) => {
				this.notes.push({ name: item.name, value: item.Id });
			});
		});

		this.getOwnership().then((res) => {
			this.fields[4].list = res.data;
			this.ownership = [];
			res.data.forEach((item) => {
				this.ownership.push({ name: item.name, value: item.Id });
			});
		});
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 10;
}
.td-name {
	position: sticky;
	min-width: 30%;
	max-width: 30%;
	left: 40px;
	margin-top: -1px;
}

.sticky-table__header2 {
	left: 0;
	z-index: 9;
	position: sticky;
	background-color: #fff;
}
.sticky-table__row2 {
	z-index: 9;
	left: 0;
	position: sticky;
}
.table-cell span {
	margin: auto 0;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
}

.table-cell span.m-auto {
	margin: auto;
}
</style>
<style>
.custom-error .vs__dropdown-toggle {
	border-radius: 10px;
	border: 1px solid red;
}

.custom-error-multiselect .multiselect__tags {
	border-radius: 10px;
	border: 1px solid red;
}

.preloader {
	z-index: 20;
	background: rgba(0, 0, 0, 0.09);
}

.td-name-head {
	margin-top: 0;
}

.table-mo {
	padding-left: 30%;
}

.table-mo:after {
	content: '';
	position: absolute;
	left: 0;
	height: calc(100% - 112px);
	top: 0;
	width: 30%;
	z-index: 8;
	background: #fff;
}

.select-label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.radio-group {
	margin-bottom: 20px;
}
.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}
.ava .icon,
.ava img {
	margin: auto;
}
.th-content .table-cell,
.table-cell {
	padding: 20px;
	/* vertical-align: top; */
}
.th-content {
	white-space: nowrap;
}

.th-content span {
	font-weight: 600;
}

.table-cell a {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-decoration-line: underline;
	white-space: nowrap;
	color: #3377ff;
}

.table-cell a:hover {
	text-decoration: none;
}

.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.icon-control {
	cursor: pointer;
}

.t-overflow {
	overflow: auto;
	min-height: 288px;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.input-box {
	position: relative;
	margin-bottom: 10px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.static-row {
	position: relative;
	margin-bottom: 10px;
}

.static-row label {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.field-value {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	padding: 0 5px;
	color: #6a6e83;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
